import Vue from 'vue'
import ProxyCalculator from './NewProxyCalculator.vue'
import LoginWidget from './LoginWidget'
import FeedbackWidget from '@/FeedbackWidget'
import vuetify from './plugins/vuetify'
import VueCookie from 'vue-cookie'
import Site from '@/Site.vue'
import ProxyChecker from '@/ProxyChecker.vue'
import Fingerprint from '@/Fingerprint.vue'
import ProxyFingerprint from '@/./ProxyFingerprint.vue'
import VueRouter from 'vue-router'

// import './sass/main.scss'
// import 'vuetify/dist/vuetify.min.css'

Vue.config.productionTip = false

Vue.use(VueRouter)

Vue.use(VueCookie)

const apps = {
  '#proxy-calculator': {
    component: ProxyCalculator,
    data: {}
  },
  '#login-widget': {
    component: LoginWidget,
    data: {}
  },
  '#partner-proxy-calculator': {
    component: ProxyCalculator,
    data: {
      props: {
        priceType: 'partner'
      }
    }
  },
  '#feedback-widget': {
    component: FeedbackWidget,
    data: {}
  },
  '#site-config': {
    component: Site,
    data: {}
  },
  '#proxy-checker-widget': {
    component: ProxyChecker,
    data: {}
  },
  '#os-fingerprint': {
    component: Fingerprint,
    data: {}
  },
  '#proxy-fingerprint': {
    component: ProxyFingerprint,
    data: {}
  }
}

for (const key of Object.keys(apps)) {
  if (document.getElementById(key.slice(1))) {
    new Vue({
      vuetify,
      render: h => h(apps[key].component, apps[key].data)
    }).$mount(key)
  }
}
